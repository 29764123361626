// 这里是新增的更改，后面使用的时候，使用这个常量
import { NAMESPACE } from '@/config'
export default {
  setItem(key, val) {
    let storage = this.getStorage()
    storage[key] = val
    window.localStorage.setItem(NAMESPACE, JSON.stringify(storage))
  },
  getStorage() {
    return JSON.parse(window.localStorage.getItem(NAMESPACE) || '{}')
  },
  getItem(key) {
    return this.getStorage()[key]
  },
  clearItem(key) {
    let storage = this.getStorage()
    delete storage[key]
    window.localStorage.setItem(NAMESPACE, JSON.stringify(storage))
  },
  clearAll() {
    window.localStorage.clear()
  }
}
